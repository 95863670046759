import React from "react";
import { Toolbar, Button, ButtonGroup, CircularProgress, withStyles } from "@material-ui/core";
import styles from "../styles";
import { injectIntl, FormattedMessage } from "react-intl";

const StatusDaysButtonGroup = ({ isLoading, setnDays, nDays, classes }) => {
  function getLoadingString(n) {
    if (n === 1) {
      return "status.reading_1_day";
    } else if (n === 3) {
      return "status.reading_3_days";
    } else {
      return "status.reading_7_days";
    }
  }
  return (
    <Toolbar className={classes.Toolbar}>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="text primary button group"
        style={{ position: "relative" }}
      >
        <Button
          disabled={isLoading}
          onClick={() => setnDays(1)}
          className={classes.ButtonLeft + (nDays === 1 ? " active" : "")}
        >
          <FormattedMessage id="status.reading_1_day" />
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => setnDays(3)}
          className={classes.ButtonLeft + (nDays === 3 ? " active" : "")}
        >
          <FormattedMessage id="status.reading_3_days" />
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => setnDays(7)}
          className={classes.ButtonLeft + (nDays === 7 ? " active" : "")}
        >
          <FormattedMessage id="status.reading_7_days" />
        </Button>

        {isLoading && (
          <div className={classes.Loading}>
            <CircularProgress size={24} style={{ marginRight: 8 }} />
            <span className={classes.LoadingSpan}>
              <FormattedMessage id="status.loading" /> <FormattedMessage id={getLoadingString(nDays)} />
            </span>
          </div>
        )}
      </ButtonGroup>
    </Toolbar>
  );
};

export default withStyles(styles)(injectIntl(StatusDaysButtonGroup));
