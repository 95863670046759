import { useEffect, useState } from "react";
import useMeterReadings from "./useMeterReadings";
import moment from "moment";
import { useIntl } from "react-intl";

function useStatus(day) {
  const [nDays, setnDays] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const intl = useIntl();

  const [dayStatus, setDayStatus] = useState({
    errorList: [],
    missingMeasuresList: [],
  });
  const [days, setDays] = useState(getDayArray(nDays));
  const { getStatus } = useMeterReadings();

  function getDayArray(n) {
    return [...Array(n).keys()].map((n) => moment(day).subtract(n, "days").format("YYYY-MM-DD"));
  }

  async function calcStatus() {
    setIsLoading(true);

    try {
      const status = await getStatus(nDays);
      setDayStatus(status);
    } catch (err) {
      setErrorMessage(intl.formatMessage({ id: "status.failed_to_fetch_status" }));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setDays(getDayArray(nDays));
    calcStatus();
  }, [nDays]);

  const wrap = (str, size = 4, delimiter = "<br>") => {
    const words = str.split(" ");
    return Array.from({ length: Math.ceil(words.length / size) }, (v, i) =>
      words.slice(i * size, i * size + size).join(" ")
    ).join(delimiter);
  };

  const formatStatusLabels = ({ intl, dayStatus, yesterday }) => {
    return [
      intl.formatMessage({ id: "status.no_measures" }),
      intl.formatMessage({ id: "status.no_error" }),
      ...Object.keys(dayStatus[yesterday].categories).map((cat) => {
        return wrap(intl.formatMessage({ id: "status." + cat }));
      }),
    ];
  };

  return {
    isLoading,
    dayStatus,
    prevDays: days,
    setnDays,
    nDays,
    formatStatusLabels,
    errorMessage,
  };
}

export default useStatus;
