import axios from "axios";
import useConfig from "./useConfig";

function useMeterReadings() {
  const { token, meterReadingsAPI } = useConfig();
  const headers = { Authorization: "Bearer " + token };

  async function getReadings(timestamp, limit = 9999999999) {
    const result = await axios.get(meterReadingsAPI + "/readings", {
      headers,
      params: { updated_since: timestamp, limit },
    });
    return result.data;
  }

  async function getStatus(n_days) {
    const result = await axios.get(meterReadingsAPI + "/status", {
      headers,
      params: { n_days },
    });
    return result.data;
  }

  return { getReadings, getStatus };
}

export default useMeterReadings;
